import React, { useEffect, useState } from 'react'
import { IItem, IOccasionResponse } from '~src/api/types/occasion'
import CreditWarning from '~src/apps/Configurator/components/CreditWarning'
import ButtonArrow from '~svg/button-arrow.svg'
import { OccasionCard } from '../Occasion/OccasionCard'
import Skeleton from '../Configurator/components/Skeleton'
import { useRootState } from '~src/hooks/store'
import { findPaymentPlan } from '~src/utils/price'
import { apiGet } from '~src/api/client'
import { IFinancialProduct } from '~src/api/types/configurator'
import { useDispatch } from 'react-redux'
import { setFinancialProducts } from '~src/store/api/actions'

type Props = {
  latestOccasions: IItem[]
  dealerNumber: number
  financeApiUrl?: string
  occasionsPageUrl?: string
  rootUrl?: string
}

export const LatestOccasions = ({
  latestOccasions,
  financeApiUrl,
  occasionsPageUrl,
  rootUrl,
}: Props) => {
  const dispatch = useDispatch()
  const {
    configuration,
    api: { financialProducts },
  } = useRootState()
  const product =
    financialProducts && findPaymentPlan(financialProducts, configuration.productGroup)

  useEffect(() => {
    if (!financialProducts.length) {
      apiGet<IFinancialProduct[]>(financeApiUrl)
        .then((response) => {
          dispatch(setFinancialProducts(response.data))
        })
        .catch((error) => {
          // Handle error if necessary
        })
    }
  }, [financialProducts])

  return (
    <div className="column-grid-block latest-occasions">
      <div className="container">
        <h2 className="block-title text-center">Ons occasion aanbod</h2>

        <CreditWarning />

        <div className="native-scroller occasions-scroller">
          {latestOccasions.map((item) => (
            <div key={item.id.carId} className="native-scroller__snap">
              <OccasionCard car={item} product={product} showBrandName={false} rootUrl={rootUrl} />
            </div>
          ))}
        </div>

        <div className="text-center">
          <a href={occasionsPageUrl} className="button button--primary">
            <span>Bekijk alle occasions</span>

            <ButtonArrow className="icon" />
          </a>
        </div>
      </div>
    </div>
  )
}
