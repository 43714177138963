import React from 'react'
import Tooltip from '~src/common/Tooltip'
import { useRootState } from '~src/hooks/store'
import { currencyFormatter } from '~src/utils/number-formatter'
import { findFinancialConfig, findPaymentPlan, getMonthlyCreditPayment } from '~src/utils/price'
import IconWarning from '~svg/warning.svg'

function OccasionPriceSummary() {
  const {
    configuration,
    api: { usingFinancialPlan, occasion, financialProducts },
    tradeIn: { oldCar },
  } = useRootState()

  const totalPrice = Number(occasion.price_online)
  const credit = totalPrice - configuration.basic.downPayment
  const product =
    financialProducts && findPaymentPlan(financialProducts, configuration.productGroup)
  const financialConfig = product && findFinancialConfig(credit, product, configuration)

  const monthlyPayment =
    product &&
    getMonthlyCreditPayment(
      credit,
      Number(financialConfig.interest_rate) / 100,
      configuration.basic.finalPayment,
      configuration.basic.durationMonths
    )

  return (
    <div className="occasion-price-summary mt-5">
      {!usingFinancialPlan ? (
        <div className="occasion-price-summary__default-price">
          <div className="h4">
            <span className="mr-2">
              Jouw {occasion.brand} {occasion.model} kost rijklaar
            </span>
            <Tooltip text="Alle consumentenprijzen occasions zijn inclusief afleverkosten. De afleverkosten bestaan uit niet vermijdbare kosten, zoals tenaamstellingskosten en APK indien noodzakelijk." />
          </div>

          <h3>{currencyFormatter.format(Number(occasion.price_online))}</h3>
        </div>
      ) : (
        <>
          {oldCar?.license_plate && (
            <div className="occasion-price-summary__trade-in-warning text-small mt-5">
              <IconWarning />
              <span className="ml-2">
                Maandbedrag alleen gebaseerd op de occasion, niet (ook) op de inruilwaarde
              </span>
            </div>
          )}
          <div className="occasion-price-summary__title">
            <div className="d-md-flex justify-content-between">
              <div className="h3">Jouw maandbedrag</div>
              <div className="h3 d-none d-md-block">
                {currencyFormatter.format(monthlyPayment)}{' '}
                <span className="text-lowercase">p/m*</span>
              </div>
            </div>
          </div>
          <div className="h5">
            <span className="mr-2">Suzuki betaalplan</span>
            <Tooltip text="Het Suzuki betaalplan is een lening waarbij je per maand afbetaalt. Je kiest zelf hoeveel maanden je daarover wilt doen. Tijdens de looptijd veranderen de rente en het maandbedrag niet." />
          </div>
          <div className="text-small">
            * BTW is niet van toepassing op de genoemde prijs. BTW wordt in één keer aanbetaald.
          </div>

          <div className="h3 d-md-none mt-2">
            {currencyFormatter.format(monthlyPayment)} <span className="text-lowercase">p/m*</span>
          </div>
        </>
      )}
    </div>
  )
}

export default OccasionPriceSummary
