/***
 * @name Dealer Filter
 *
 */

import React from 'react'
import { IDealerFilter } from '~src/apps/DealerMap/types'

const DealerFilter: React.FC<IDealerFilter> = ({ filter, setFilter, filterOptions }) => {
  const handleChange = (event: React.FormEvent) => {
    const value = event.target as HTMLSelectElement
    setFilter(value.value)
  }

  return (
    <div className="dealer-filter">
      <h5>Toon:</h5>

      <div className="dealer-filter__select-list">
        <div className="input-select">
          <select className="input-select__select" value={filter} onChange={handleChange}>
            {Object.values(filterOptions).map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="dealer-filter__radio-list">
        {Object.values(filterOptions).map((option, index) => (
          <div className="dealer-filter__radio-item" key={index}>
            <input
              type="radio"
              id={`dealer-filter-${index}`}
              name="dealer-filter"
              value={option}
              onChange={handleChange}
              checked={filter === option}
            />
            <label htmlFor={`dealer-filter-${index}`}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DealerFilter
