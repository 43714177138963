import {
  ContractPropertyType,
  IAccessory,
  IColor,
  IEdition,
  IFinancialData,
  IFinancialProduct,
  IFinancialProductConfig,
  IModelProducts,
  IPriceRangeData,
  ISimpleEdition,
} from '~src/api/types/configurator'
import { IConfigurationState, ProductGroup } from '~src/store/configuration/types'
import { FinancialPlan, creditPlans, leasePlans } from '~src/store/navigation/types'
import { binarySearch } from '~src/utils/collection'
import { findMin, floorDecimals, sum } from '~src/utils/math'

// Configurator tool default percentages
export const DEFAULT_DOWN_PAYMENT_PERCENTAGE = 0.25
export const MARINE_DOWN_PAYMENT_PERCENTAGE = 0.35
export const DEFAULT_FINAL_PAYMENT_PERCENTAGE = 0.35

export const simpleTotalPrice = (
  edition: ISimpleEdition,
  financial: IFinancialData,
  configuration: IConfigurationState,
  plan: FinancialPlan
) =>
  basePrice(edition, plan) +
  basicConfigurationPriceDelta(configuration, 'claim_free_years', financial, plan) +
  basicConfigurationPriceDelta(configuration, 'duration', financial, plan) +
  basicConfigurationPriceDelta(configuration, 'km_per_year', financial, plan)

export const getTotalPrice = (
  edition: IEdition,
  financial: IFinancialData,
  configuration: IConfigurationState,
  plan: FinancialPlan
) =>
  simpleTotalPrice(edition, financial, configuration, plan) +
  colorPrice(currentColor(edition, configuration), configuration, financial, plan) +
  sum(
    edition?.packages
      ?.filter((a) => configuration.car.packageCodes.includes(a.code))
      .map((a) => accessoryPrice(a, financial, configuration, plan)) ?? [],
    (a) => a
  ) +
  sum(
    edition?.accessories
      ?.filter((a) => configuration.car.accessoryCodes.includes(a.code))
      .map((a) => accessoryPrice(a, financial, configuration, plan)) ?? [],
    (value) => value
  ) +
  sum(
    configuration.car.upsellCodes.map((code) => upsellPrice(code, edition)),
    (value) => value
  )

export const regularTotalPrice = (edition: IEdition, configuration: IConfigurationState) =>
  getTotalPrice(edition, null, configuration, null)

export const modelBasePrice = (model: IModelProducts, plan: FinancialPlan) =>
  Math.min(...model.editions.map((e) => basePrice(e, plan)))

/**
 * Function used to calculate the standard downpayment and final payment for a certain price.
 *
 * @param price The price of the car
 * @returns credit, standard down and final payment
 */
export const getStandardFinancialValues = (price: number) => {
  const standardDownPayment = Math.floor(price * DEFAULT_DOWN_PAYMENT_PERCENTAGE)
  const credit = price - standardDownPayment
  const standardFinalPayment = Math.floor(credit * DEFAULT_FINAL_PAYMENT_PERCENTAGE)

  return { credit, standardDownPayment, standardFinalPayment }
}

export const basePrice = (edition: ISimpleEdition, plan: FinancialPlan) =>
  plan === 'private-lease'
    ? Number(edition?.baseprice_privatelease)
    : plan === 'operational-lease'
    ? Number(edition?.baseprice_operationallease)
    : Number(edition?.from_price)

export const colorPrice = (
  color: IColor,
  configuration: IConfigurationState,
  financial: IFinancialData,
  plan: FinancialPlan
) =>
  leasePlans.includes(plan)
    ? findMonthlyLeasePrice(Number(color?.price), financial?.lease_options, configuration)
    : creditPlans.includes(plan)
    ? getMonthlyCreditPayment(
        Number(color?.price),
        Number(
          findCurrentFinancialConfig(0, financial?.financial_products, configuration, plan)
            .interest_rate
        ) / 100,
        0,
        configuration.basic.durationMonths
      )
    : Number(color?.price ?? 0)

export const currentColor = (edition: IEdition, configuration: IConfigurationState) =>
  edition?.colors.find((c) => c.code === configuration.car.color)

export const basicConfigurationPriceDelta = (
  configuration: IConfigurationState,
  type: ContractPropertyType,
  financial: IFinancialData,
  plan: FinancialPlan
) =>
  plan === 'private-lease'
    ? Number(
        privateLeaseBasicConfigurationPriceDelta(configuration, type, financial)?.price ?? null
      )
    : plan === 'operational-lease'
    ? Number(operationalBasicConfigurationPriceDelta(configuration, type, financial)?.price ?? null)
    : null

export const privateLeaseBasicConfigurationPriceDelta = (
  configuration: IConfigurationState,
  type: ContractPropertyType,
  financial: IFinancialData
) =>
  financial.privatelease_deltas.find(
    (d) => d.delta_type === type && d.delta_key === basicConfigurationValue(configuration, type)
  )

export const operationalBasicConfigurationPriceDelta = (
  configuration: IConfigurationState,
  type: ContractPropertyType,
  financial: IFinancialData
) =>
  financial.operationallease_deltas.find(
    (d) => d.delta_type === type && d.delta_key === basicConfigurationValue(configuration, type)
  )

const basicConfigurationValue = (
  configuration: IConfigurationState,
  type: ContractPropertyType
) => {
  switch (type) {
    case 'claim_free_years':
      return configuration.basic.claimFreeYears
    case 'duration':
      return configuration.basic.durationMonths
    case 'km_per_year':
      return configuration.basic.yearlyKilometers
    default:
      return 0
  }
}

export const accessoryPrice = (
  accessory: IAccessory,
  financial: IFinancialData,
  configuration: IConfigurationState,
  plan: FinancialPlan
) =>
  leasePlans.includes(plan)
    ? findMonthlyLeasePrice(
        Number(accessory.discount_price ?? accessory.price),
        financial.lease_accessories,
        configuration
      )
    : creditPlans.includes(plan)
    ? getMonthlyCreditPayment(
        Number(accessory.discount_price ?? accessory.price),
        Number(
          findCurrentFinancialConfig(0, financial.financial_products, configuration, plan)
            .interest_rate
        ) / 100,
        0,
        configuration.basic.durationMonths
      )
    : Number(accessory.discount_price ?? accessory.price)

export const accessoryOriginalPrice = (
  accessory: IAccessory,
  financial: IFinancialData,
  configuration: IConfigurationState,
  plan: FinancialPlan
) =>
  accessory.discount_price
    ? leasePlans.includes(plan)
      ? findMonthlyLeasePrice(Number(accessory.price), financial.lease_accessories, configuration)
      : creditPlans.includes(plan)
      ? getMonthlyCreditPayment(
          Number(accessory.price),
          Number(
            findCurrentFinancialConfig(0, financial.financial_products, configuration, plan)
              .interest_rate
          ) / 100,
          0,
          configuration.basic.durationMonths
        )
      : Number(accessory.price)
    : null

export const findMonthlyLeasePrice = (
  price: number,
  priceRanges: IPriceRangeData[],
  configuration: IConfigurationState
) =>
  getMonthlyLeasePrice(
    binarySearch(priceRanges, (delta) => priceRangeCompare(price, delta)),
    configuration
  )

export const priceRangeCompare = (price: number, range: IPriceRangeData) =>
  Number(range.price_from) < price && Number(range.price_till) >= price
    ? 0
    : Number(range.price_till) < price
    ? 1
    : -1

const getMonthlyLeasePrice = (range: IPriceRangeData, configuration: IConfigurationState) => {
  if (!range) return 0
  switch (configuration.basic.durationMonths) {
    case 12:
      return Number(range.price_12_months)
    case 24:
      return Number(range.price_24_months)
    case 36:
      return Number(range.price_36_months)
    case 48:
      return Number(range.price_48_months)
    case 60:
      return Number(range.price_60_months)
    case 72:
      return Number(range.price_72_months)
    default:
      return 0
  }
}

export const findPaymentPlan = (
  financialProducts: IFinancialProduct[],
  productGroup: ProductGroup
): IFinancialProduct =>
  financialProducts?.find(
    (p) => p.name.toLowerCase().includes('betaalplan') && p.product_group === productGroup
  )

export const findMinMaxDuration = (
  financialProduct: IFinancialProduct
): { min: number; max: number } => {
  if (!financialProduct) return { min: 0, max: 0 }
  return {
    min: financialProduct.configs[0].duration,
    max: financialProduct.configs[financialProduct.configs.length - 1].duration,
  }
}

export const findFinancialLease = (financialProducts: IFinancialProduct[]) =>
  financialProducts?.find((p) => p.name.toLowerCase().includes('financial lease'))

export const findCurrentFinancialConfig = (
  credit: number,
  financialProducts: IFinancialProduct[],
  configuration: IConfigurationState,
  plan: FinancialPlan
) =>
  plan === 'payment-plan'
    ? findFinancialConfig(
        credit,
        findPaymentPlan(financialProducts, configuration.productGroup),
        configuration
      )
    : plan === 'financial-lease'
    ? findFinancialConfig(credit, findFinancialLease(financialProducts), configuration)
    : null

/**
 * Finds the financial config for the current duration months with the lowest max credit.
 *
 * @param credit the total price of the car
 */
export const findFinancialConfig = (
  credit: number,
  product: IFinancialProduct,
  configuration: IConfigurationState
): IFinancialProductConfig =>
  product?.configs.length > 0 &&
  findMin(
    product?.configs.filter(
      (c) => c.duration === configuration.basic.durationMonths && Number(c.max_credit) >= credit
    ),
    (c) => Number(c.max_credit)
  )

////////////////
// NET ADDITION
////////////////

export const fiscalPrice = (edition: IEdition, configuration: IConfigurationState) =>
  Number(edition.gross_price_incl) + Number(currentColor(edition, configuration).price)

export const monthlyNetAddition = (edition: IEdition, configuration: IConfigurationState) =>
  (fiscalPrice(edition, configuration) * 0.22 * (configuration.basic.taxScale / 100)) /
  // net addition is a yearly, so we divide it by the 12 months of a year
  12

//////////////////
// CREDIT PAYMENTS
//////////////////

/**
 * Returns the maximum final payment that is allowed for a loan.
 *
 * This value is capped by a fixed maximum value as well as a percentage
 * on the credit value as given by a financial product configuration.
 *
 * @param credit The credit equals the car price - down payment (if any)
 */
export const getMaxFinalCreditPayment = (
  credit: number,
  financialConfig: IFinancialProductConfig
) =>
  Math.min(
    // There is a percentage maximum of the credit you can pay at the end.
    credit * (Number(financialConfig.max_final_term_percentage) / 100),
    // There is a fixed maximum amount you can pay at the end.
    Number(financialConfig.max_final_term)
  )

/**
 * Calculates the monthly payment for a loan rounding to 2 decimals.
 *
 * The monthly payment is calculated with an annuity interest on the credit price
 * minus the final payment, plus regular monthly interest on the final payment.
 *
 * https://nl.wikipedia.org/wiki/Annu%C3%AFteit#Vermogensverloop_bij_een_lening
 *
 * @param credit The price that is to be loaned. (NL: kredietbedrag)
 * @param annualInterestRate The percentage between 0-1 (e.g. `0.069`). (NL: Jaarlijkse kostenpercentage)
 * @param finalPayment The residual debt amount at the end of the loan period. (NL: slottermijn)
 * @param months The amount of months for this loan. (NL: looptijd)
 */
export const getMonthlyCreditPayment = (
  credit: number,
  annualInterestRate: number,
  finalPayment: number,
  months: number
) =>
  floorDecimals(
    // You pay annuity interest each month over the credit price minus the final payment.
    // This part also includes a portion of the loan you pay back.
    (credit - finalPayment) * monthlyAnnuityInterest(annualInterestRate, months) +
      // You als pay regular interest each month for the finalPayment.
      finalPayment * monthlyInterestRate(annualInterestRate),
    2
  )

export const monthlyAnnuityInterest = (annualInterestRate: number, months: number) =>
  monthlyInterestRate(annualInterestRate) /
  (1 - Math.pow(1 + monthlyInterestRate(annualInterestRate), -months))

export const monthlyInterestRate = (annualInterestRate: number) =>
  // ( 1.069 ^ (1/12) ) - 1 =~ 0.005575789844
  // The Alcredis method rounds this number in a quirky way.
  floorDecimals(Math.pow(1 + annualInterestRate + 0.000499999, 1 / 12) - 1, 5)

/**
 * Returns the monthly credit payment for a certain price given a configuration.
 *
 * @param price The price of the car.
 */
export const getMonthlyCreditPaymentForConfiguration = (
  price: number,
  configuration: IConfigurationState,
  financialConfig: IFinancialProductConfig
) => {
  const credit = price - configuration.basic.downPayment
  const maxFinalPayment = getMaxFinalCreditPayment(credit, financialConfig)

  return getMonthlyCreditPayment(
    credit,
    Number(financialConfig.interest_rate) / 100,
    maxFinalPayment,
    configuration.basic.durationMonths
  )
}

/**
 * Returns the monthly credit payment for a certain price with the standard percentages
 * This is used in step 1 and 2 of the configurator, because these show a list of models/editions,
 * for which it makes more sense to use a standard, instead of a configurable value.
 *
 * @param price The price of the car.
 */
export const getMonthlyCreditPaymentForStandardPercentages = (
  price: number,
  configuration: IConfigurationState,
  financialConfig: IFinancialProductConfig
) => {
  const { standardFinalPayment, credit } = getStandardFinancialValues(price)

  return getMonthlyCreditPayment(
    credit,
    Number(financialConfig.interest_rate) / 100,
    standardFinalPayment,
    configuration.basic.durationMonths
  )
}

export const upsellPrice = (code: string, edition: ISimpleEdition): number => {
  switch (code) {
    case 'flexlease':
      return Number(edition.flexprice_privatelease)
    default:
      return 0
  }
}
