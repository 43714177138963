import $ from 'jquery'
import React, { useEffect, useRef } from 'react'
import TooltipIcon from '~svg/tooltip.svg'

const Tooltip = ({ text }: { text: string }) => {
  const ref = useRef<HTMLSpanElement>()

  useEffect(() => {
    $(ref.current).tooltip({
      // @ts-ignore false error, 'window' is a valid boundary
      boundary: 'window',
    })
  }, [])

  useEffect(() => {
    $(ref.current).attr('data-original-title', text).tooltip('update')
  }, [text])

  return (
    <span ref={ref} className="tooltip-toggler">
      <TooltipIcon width={16} height={16} />
    </span>
  )
}

export default Tooltip
