/***
 * @name Dealer Info
 *
 */

import clsx from 'clsx'
import React from 'react'
import ScrollLock from 'react-scrolllock'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { IDealerInfo } from '~src/apps/DealerMap/types'
import { isMobile } from '~src/utils/breakpoints'
import ButtonArrow from '~svg/button-arrow.svg'
import IconCheckmark from '~svg/checkmark.svg'
import IconClock from '~svg/clock.svg'
import IconEmail from '~svg/email.svg'
import IconExternalLink from '~svg/external-link.svg'
import IconPhone from '~svg/phone.svg'
import IconWhatsApp from '~svg/whatsapp.svg'

const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation()
}

const CLOSED = 'Gesloten'

const DealerInfo: React.FC<IDealerInfo> = ({
  detail_page_url,
  phone_number,
  phone_number_sales,
  phone_number_workshop,
  display_phone_number_sales,
  display_phone_number_workshop,
  whatsapp_url,
  whatsapp_readable,
  email,
  email_sales,
  email_workshop,
  website,
  website_readable,
  showroom_hours_fri,
  showroom_hours_mon,
  showroom_hours_sat,
  showroom_hours_sun,
  showroom_hours_tue,
  showroom_hours_thu,
  showroom_hours_wed,
  workshop_hours_fri,
  workshop_hours_mon,
  workshop_hours_sat,
  workshop_hours_sun,
  workshop_hours_tue,
  workshop_hours_thu,
  workshop_hours_wed,
  order,
  isMultiple,
  viewMode,
  street_name,
  house_number,
  house_number_suffix,
  zipcode,
  city,
  name,
  productGroup,
  is_after_sales_dealer,
  is_sales_dealer,
  is_exclusive_dealer,
  motor_type_road,
  motor_type_atv,
  motor_type_cross,
  display_phone_number,
  formType,
  brochure_request_page_url,
  showroom_appointment_page_url,
  quote_request_page_url,
  service_appointment_page_url,
  testdrive_page_url,
  order_page_url,
  dealerDetailUrl,
  isModal,
}) => {
  // Redirect URLs
  const redirectURLs: any = {
    werkplaatsafspraak: service_appointment_page_url,
    brochureaanvraag: brochure_request_page_url,
    offerteaanvraag: quote_request_page_url,
    proefritaanvraag: testdrive_page_url,
    bestelling: order_page_url,
    showroomafspraak: showroom_appointment_page_url,
  }

  const queryStringValues = redirectURLs[formType]?.includes('?')
    ? '&' + location.search.substring(1)
    : location.search

  const redirectURL =
    viewMode === 'form_cta' && redirectURLs[formType]
      ? redirectURLs[formType] + queryStringValues
      : null

  const logo: HTMLImageElement = document.querySelector('.page-header__logo img')

  const viewShowroomHours = [
    showroom_hours_mon,
    showroom_hours_tue,
    showroom_hours_wed,
    showroom_hours_thu,
    showroom_hours_fri,
    showroom_hours_sat,
    showroom_hours_sun,
  ].some((i) => !!i)

  const viewWorkshopHours = [
    workshop_hours_mon,
    workshop_hours_tue,
    workshop_hours_wed,
    workshop_hours_thu,
    workshop_hours_fri,
    workshop_hours_sat,
    workshop_hours_sun,
  ].some((i) => !!i)

  const hasShowroom = Boolean(viewShowroomHours || display_phone_number_sales || email_sales)
  const hasWorkshop = Boolean(
    viewWorkshopHours || display_phone_number_workshop || email_workshop || email
  )

  return (
    <div
      className={isMultiple ? 'dealer-info is-multiple' : 'dealer-info'}
      itemScope
      itemType="https://schema.org/AutoDealer"
    >
      <ScrollLock isActive={isModal}>
        <div className={clsx('scroll-container', isModal && 'is-modal')}>
          <div className="dealer-info__title" data-number={order}>
            <meta itemProp="name" content={name} />
            {logo && logo.src && <meta itemProp="image" content={logo.src} />}

            {phone_number && <meta itemProp="telephone" content={phone_number} />}
            {email && <meta itemProp="email" content={email} />}
            {viewMode !== 'contact_detail' && (
              <div className={`dealer-info__marker${!is_sales_dealer ? ' alt' : ''}`}></div>
            )}

            <h3 className="dealer-info__dealer-name h5 mb-0">{name}</h3>

            {productGroup === 'car' && (
              <ul className="dealer-info__facilities">
                {is_sales_dealer && (
                  <li className="dealer-info__facility">
                    <IconCheckmark className="icon" />
                    Nieuwe Suzuki&apos;s
                  </li>
                )}
                {is_after_sales_dealer && (
                  <li className="dealer-info__facility">
                    <IconCheckmark className="icon" />
                    Occasions
                  </li>
                )}
                {is_after_sales_dealer && (
                  <li className="dealer-info__facility">
                    <IconCheckmark className="icon" />
                    Onderhoud
                  </li>
                )}
              </ul>
            )}

            {productGroup === 'motors' && (
              <ul className="dealer-info__facilities">
                {is_exclusive_dealer && (
                  <li className="dealer-info__facility">
                    <strong>Exclusief Suzuki dealer:</strong>
                  </li>
                )}
                {motor_type_road && (
                  <li className="dealer-info__facility">
                    <IconCheckmark className="icon" />
                    Wegmotoren
                  </li>
                )}
                {motor_type_atv && (
                  <li className="dealer-info__facility">
                    <IconCheckmark className="icon" />
                    ATV
                  </li>
                )}
                {motor_type_cross && (
                  <li className="dealer-info__facility">
                    <IconCheckmark className="icon" />
                    Motorcross
                  </li>
                )}
              </ul>
            )}
            <div
              className="dealer-info__address"
              itemProp="address"
              itemScope
              itemType="https://schema.org/PostalAddress"
            >
              <div itemProp="streetAddress">
                {street_name} {house_number} {house_number_suffix}
              </div>

              <div>
                <span itemProp="postalCode">{zipcode}</span>{' '}
                <span itemProp="addressLocality">{city}</span>
              </div>
            </div>
          </div>

          <div className="dealer-info__links">
            {display_phone_number && (
              <a href={`tel:${phone_number}`} className="dealer-info__phone-link">
                <IconPhone className="icon" />
                <span>{display_phone_number}</span>
              </a>
            )}
            {whatsapp_url && (
              <a href={whatsapp_url} className="dealer-info__whatsapp-link">
                <IconWhatsApp className="icon" />
                <span>{whatsapp_readable || whatsapp_url}</span>
              </a>
            )}

            {website && productGroup === 'marine' && (
              <a
                href={website}
                className="dealer-info__external-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconExternalLink className="icon" />
                <span>{website_readable}</span>
              </a>
            )}
          </div>

          <Tabs className={clsx('dealer-tabs', hasShowroom && hasWorkshop && 'has-multiple-items')}>
            <TabList>
              {hasShowroom && <Tab>Showroom</Tab>}
              {hasWorkshop && <Tab>Werkplaats</Tab>}
            </TabList>
            {hasShowroom && (
              <TabPanel>
                <DealerShowroomInfo
                  display_phone_number_sales={display_phone_number_sales}
                  phone_number_sales={phone_number_sales}
                  email_sales={email_sales}
                  viewShowroomHours={viewShowroomHours}
                  showroom_hours_mon={showroom_hours_mon}
                  showroom_hours_tue={showroom_hours_tue}
                  showroom_hours_wed={showroom_hours_wed}
                  showroom_hours_thu={showroom_hours_thu}
                  showroom_hours_fri={showroom_hours_fri}
                  showroom_hours_sat={showroom_hours_sat}
                  showroom_hours_sun={showroom_hours_sun}
                />
              </TabPanel>
            )}
            {hasWorkshop && (
              <TabPanel>
                <DealerWorkshopInfo
                  display_phone_number_workshop={display_phone_number_workshop}
                  phone_number_workshop={phone_number_workshop}
                  email_workshop={email_workshop}
                  email={email}
                  viewWorkshopHours={viewWorkshopHours}
                  workshop_hours_mon={workshop_hours_mon}
                  workshop_hours_tue={workshop_hours_tue}
                  workshop_hours_wed={workshop_hours_wed}
                  workshop_hours_thu={workshop_hours_thu}
                  workshop_hours_fri={workshop_hours_fri}
                  workshop_hours_sat={workshop_hours_sat}
                  workshop_hours_sun={workshop_hours_sun}
                />
              </TabPanel>
            )}
          </Tabs>

          {redirectURL && (
            <div>
              <a className="button button--primary" href={redirectURL}>
                Selecteer dealer
                <ButtonArrow className="icon" />
              </a>
            </div>
          )}

          {viewMode !== 'form_cta' && (
            <div className="dealer-info__actions">
              {viewMode !== 'contact_detail' && (
                <a className="button button--primary" href={detail_page_url}>
                  {!isMobile() ? 'Meer informatie' : 'Meer info'}
                  <ButtonArrow className="icon" />
                </a>
              )}

              <a
                className={`button button--primary ${
                  viewMode !== 'contact_detail' ? 'button--secondary' : ''
                }`}
                href={`https://www.google.nl/maps/dir//${street_name}+${house_number}${
                  house_number_suffix ? '+' + house_number_suffix : ''
                },+${zipcode?.replace(/\s/g, '')}+${city}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Route</span>
                <ButtonArrow className="icon" />
              </a>

              {dealerDetailUrl && (
                <a className="button button--secondary" href={dealerDetailUrl}>
                  Bekijk alle occasions
                  <ButtonArrow className="icon" />
                </a>
              )}
            </div>
          )}
        </div>
      </ScrollLock>
    </div>
  )
}

const DealerShowroomInfo = ({
  display_phone_number_sales,
  phone_number_sales,
  email_sales,
  viewShowroomHours,
  showroom_hours_mon,
  showroom_hours_tue,
  showroom_hours_wed,
  showroom_hours_thu,
  showroom_hours_fri,
  showroom_hours_sat,
  showroom_hours_sun,
}: {
  display_phone_number_sales: string
  phone_number_sales: string
  email_sales: string
  viewShowroomHours: boolean
  showroom_hours_mon: string
  showroom_hours_tue: string
  showroom_hours_wed: string
  showroom_hours_thu: string
  showroom_hours_fri: string
  showroom_hours_sat: string
  showroom_hours_sun: string
}) => (
  <div className="dealer-info__opening-times">
    {display_phone_number_sales && (
      <div className="dealer-info__phone-link">
        <IconPhone className="icon" />

        <a href={`tel:${phone_number_sales}`} onClick={stopPropagation}>
          <span>{display_phone_number_sales}</span>
        </a>
      </div>
    )}

    {email_sales && (
      <div className="dealer-info__email-link">
        <IconEmail className="icon" />

        <a href={'mailto:' + email_sales} onClick={stopPropagation}>
          <span>{email_sales}</span>
        </a>
      </div>
    )}

    {viewShowroomHours && (
      <div className="d-flex align-items-start">
        <IconClock className="icon icon-clock" />

        <ul className="dealer-info__opening-times-list">
          <li>
            <span>Maandag</span> {showroom_hours_mon || CLOSED}
            {showroom_hours_mon && (
              <meta itemProp="openingHours" content={`Mo ${showroom_hours_mon}`} />
            )}
          </li>

          <li>
            <span>Dinsdag</span> {showroom_hours_tue || CLOSED}
            {showroom_hours_tue && (
              <meta itemProp="openingHours" content={`Tu ${showroom_hours_tue}`} />
            )}
          </li>

          <li>
            <span>Woensdag</span> {showroom_hours_wed || CLOSED}
            {showroom_hours_wed && (
              <meta itemProp="openingHours" content={`We ${showroom_hours_wed}`} />
            )}
          </li>

          <li>
            <span>Donderdag</span> {showroom_hours_thu || CLOSED}
            {showroom_hours_thu && (
              <meta itemProp="openingHours" content={`Th ${showroom_hours_thu}`} />
            )}
          </li>

          <li>
            <span>Vrijdag</span> {showroom_hours_fri || CLOSED}
            {showroom_hours_fri && (
              <meta itemProp="openingHours" content={`Fr ${showroom_hours_fri}`} />
            )}
          </li>

          <li>
            <span>Zaterdag</span> {showroom_hours_sat || CLOSED}
            {showroom_hours_sat && (
              <meta itemProp="openingHours" content={`Sa ${showroom_hours_sat}`} />
            )}
          </li>

          <li>
            <span>Zondag</span> {showroom_hours_sun || CLOSED}
            {showroom_hours_sun && (
              <meta itemProp="openingHours" content={`Su ${showroom_hours_sun}`} />
            )}
          </li>
        </ul>
      </div>
    )}
  </div>
)

const DealerWorkshopInfo = ({
  display_phone_number_workshop,
  phone_number_workshop,
  email_workshop,
  email,
  viewWorkshopHours,
  workshop_hours_mon,
  workshop_hours_tue,
  workshop_hours_wed,
  workshop_hours_thu,
  workshop_hours_fri,
  workshop_hours_sat,
  workshop_hours_sun,
}: {
  display_phone_number_workshop: string
  phone_number_workshop: string
  email_workshop: string
  email: string
  viewWorkshopHours: boolean
  workshop_hours_mon: string
  workshop_hours_tue: string
  workshop_hours_wed: string
  workshop_hours_thu: string
  workshop_hours_fri: string
  workshop_hours_sat: string
  workshop_hours_sun: string
}) => (
  <div className="dealer-info__opening-times">
    {display_phone_number_workshop && (
      <div className="dealer-info__phone-link">
        <IconPhone className="icon" />

        <a href={`tel:${phone_number_workshop}`} onClick={stopPropagation}>
          <span>{display_phone_number_workshop}</span>
        </a>
      </div>
    )}

    {(email || email_workshop) && (
      <div className="dealer-info__email-link">
        <IconEmail className="icon" />

        <a href={`mailto:${email_workshop ?? email}`} onClick={stopPropagation}>
          <span>{email_workshop ?? email}</span>
        </a>
      </div>
    )}

    {viewWorkshopHours && (
      <div className="d-flex align-items-start">
        <IconClock className="icon icon-clock" />

        <ul className="dealer-info__opening-times-list">
          <li>
            <span>Maandag</span> {workshop_hours_mon || CLOSED}
          </li>

          <li>
            <span>Dinsdag</span> {workshop_hours_tue || CLOSED}
          </li>

          <li>
            <span>Woensdag</span> {workshop_hours_wed || CLOSED}
          </li>

          <li>
            <span>Donderdag</span> {workshop_hours_thu || CLOSED}
          </li>

          <li>
            <span>Vrijdag</span> {workshop_hours_fri || CLOSED}
          </li>

          <li>
            <span>Zaterdag</span> {workshop_hours_sat || CLOSED}
          </li>

          <li>
            <span>Zondag</span> {workshop_hours_sun || CLOSED}
          </li>
        </ul>
      </div>
    )}
  </div>
)

export default DealerInfo
