import { createAction } from '@reduxjs/toolkit'
import queryString from 'query-string'
import { apiGet, apiGetEditionDetails } from '~src/api/client'
import { IEdition, IFinancialProduct, IModelProducts } from '~src/api/types/configurator'
import { RootState } from '~src/store'
import { defaultBasicConfiguration } from '~src/store/configuration/initialState'
import { ICarConfiguration, IConfigurationState } from '~src/store/configuration/types'
import { deserializeConfiguration, deserializeTradeInCar } from '~src/store/url-serializers'
import {
  DEFAULT_DOWN_PAYMENT_PERCENTAGE,
  DEFAULT_FINAL_PAYMENT_PERCENTAGE,
  MARINE_DOWN_PAYMENT_PERCENTAGE,
  regularTotalPrice,
} from '~src/utils/price'

// This is a special action only called once in the beginning after the first api call is done.
export const setInitialState = createAction<Omit<RootState, 'navigation'>>('initialize')

/**
 * Builds the initial state from the query parameters and the models api endpoint.
 * When an edition is also set in the query parameters, or when using a `financeModal`,
 * another api call for the edition is made.
 *
 * Navigation state is initialized elsewhere, so that we can show skeleton loaders
 * while the global initial state is building.
 */
export const buildInitialState = async (
  modelsApiUrl: string,
  financialProducts: IFinancialProduct[] = []
): Promise<Omit<RootState, 'navigation'>> => {
  const models = (await apiGet<IModelProducts[]>(modelsApiUrl)).data

  const queryParams = queryString.extract(window.location.search || '')
  const oldCar = deserializeTradeInCar(queryParams)

  // First we get a partial configuration state from the url.
  const urlConfiguration = deserializeConfiguration(queryParams, queryString.extract(modelsApiUrl))

  const model = urlConfiguration.model
    ? models.find((m) => m.code === urlConfiguration.model)
    : models[0]

  const simpleEdition =
    model && urlConfiguration.car.edition
      ? model.editions.find((e) => e.code === urlConfiguration.car.edition)
      : model.editions[0]

  const [edition, financialData] = simpleEdition ? await apiGetEditionDetails(simpleEdition) : []

  const car: ICarConfiguration = {
    accessoryCodes: [],
    packageCodes: [],
    upsellCodes: [],
    edition: urlConfiguration.car.edition ?? edition?.code,
    color: edition?.colors[0]?.code,
    ...urlConfiguration.car,
  }

  const initialState: Omit<RootState, 'navigation'> = {
    api: {
      edition: edition ?? null,
      editionFinancialData: financialData ?? null,
      models,
      financialProducts,
      occasion: null,
      usingFinancialPlan: null,
    },
    configuration: {
      productGroup: urlConfiguration.productGroup ?? 'car',
      model: urlConfiguration.model ?? (model.code as any),
      car,
      basic: {
        // We have some absolute basic configuration
        ...defaultBasicConfiguration,
        ...(edition
          ? // Then some default basic configuration specific for an edition
            defaultBasicConfigurationForEdition(
              {
                productGroup: urlConfiguration.productGroup ?? 'car',
                model: urlConfiguration.model ?? '',
                car,
                basic: { ...defaultBasicConfiguration, ...urlConfiguration.basic },
              },
              edition
            )
          : undefined),
        // And finally the current configuration state extracted from the url.
        // This should overwrite the default configuration(s)
        ...urlConfiguration.basic,
      },
    },
    comparison: { selectedCarCodes: [] },
    tradeIn: {
      status: null,
      oldCar,
      newCar: null,
      successMessage: null,
      customer: null,
    },
  }

  return initialState
}

const defaultBasicConfigurationForEdition = (
  configuration: IConfigurationState,
  edition: IEdition
) => {
  const totalPrice =
    configuration.basic.regularPriceOverride ?? regularTotalPrice(edition, configuration)
  const credit = totalPrice - configuration.basic.downPayment

  return {
    downPayment: Math.floor(totalPrice * DEFAULT_DOWN_PAYMENT_PERCENTAGE),
    finalPayment:
      configuration.productGroup !== 'car'
        ? 0
        : Math.floor(credit * DEFAULT_FINAL_PAYMENT_PERCENTAGE),
    creditOverride:
      configuration.productGroup !== 'car'
        ? Math.floor(totalPrice * (1 - MARINE_DOWN_PAYMENT_PERCENTAGE))
        : undefined,
  }
}
